<!-- eslint-disable vue/no-mutating-props -->
<template>
    <Dialog v-model:visible="editItemDialog" :style="{width: '450px'}" header="Data Detail" :modal="true" class="p-fluid">
         <div class="field">
            <label>Distributor Kode</label>
            <InputText v-model="item.dist_code" required="true" autofocus :class="{ 'p-invalid': errorEdit.dist_code }" disabled/>
            <small class="p-invalid" style="color: red" v-if="errorEdit.dist_code" >{{ errorEdit.dist_code[0] }}</small >
        </div>
         <div class="field">
            <label>Distributor Nama</label>
            <InputText v-model="item.dist_name" required="true" autofocus :class="{ 'p-invalid': errorEdit.dist_name }" />
            <small class="p-invalid" style="color: red" v-if="errorEdit.dist_name" >{{ errorEdit.dist_name[0] }}</small >
        </div>
        <div class="field">
            <label>Region</label>
            <Dropdown dataKey="region_id" 
                    ref="drgn" v-model="item.region_id" 
                    :loading="loadingDropdownRegion" 
                    :options="dataDropdownRegion" 
                    :class="{ 'p-invalid': errorEdit.region_id }" 
                    optionLabel="region_name" optionValue="region_id" 
                    placeholder="Pilih Region" :filter="true" 
                    :showClear="true" @filter="searchDropdownRegion($event,'edit')"
            />
            <small class="p-invalid" style="color: red" v-if="errorEdit.region_id" >{{ errorEdit.region_id[0] }}</small>
        </div>
         <div class="field">
            <label>Status</label>
            <Dropdown v-model="item.is_active" :options="dataDropdownStatus" optionLabel="name" optionValue="code" placeholder="Pilih Status" :class="{ 'p-invalid': errorEdit.status }"/>
                <small class="p-invalid" style="color: red" v-if="errorEdit.status" >{{ errorEdit.status[0] }}</small>
        </div>
        <template #footer>
            <Button label="Batal" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
            <Button label="Simpan" icon="pi pi-check" class="p-button-text" @click="updateItem" :loading="loading"/>
        </template>
    </Dialog>
</template>


<script>

import { mapGetters } from "vuex";

export default {
    props: [
		"item",
    ],
    data() {
        return {
            // loading
            loading: false,
            loadingDropdownRegion: false,

            // dataDropdown
            dataDropdownRegion: null,
            dataDropdownStatus: [
                {name: 'AKTIF', code: 'y'},
                {name: 'TIDAK AKTIF', code: 'n'},
            ],

            // edit
            editItemDialog: false,
        }
    },
    mounted() {
        this.$store.commit('setErrorEdit', {});
    },
    watch: {
        editItemDialog: {
            handler() {
                setTimeout(() => {
                    if(this.editItemDialog == true){
                        this.searchDropdownRegion('', 'edit', this.item.region_name);
                    }
                    this.$store.commit('setErrorEdit', {});
                }, 500);
            },
        }
    },
    computed:{
        ...mapGetters(['errorEdit']),
		...mapGetters("auth", ["user"]),
    },
    methods: {
        // DROPDOWN
        searchDropdownRegion(event, purpose, valueEdit){
            setTimeout(() => {
                if(valueEdit){
                    this.$refs.drgn.filterValue = valueEdit;
                }

                if(purpose == "edit"){
                    this.loadingDropdownRegion = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/master-region-v2',
                    params: {
                        "search" : valueEdit ? valueEdit : event.value,
                    }
                })
                .then(res => {

                    if(purpose == "edit"){
                        this.dataDropdownRegion = res.data.data;
                        this.loadingDropdownRegion = false;
                    }else if(purpose == null){
                        this.dataDropdownRegion = res.data.data;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            }, 250);
        },
        // EDIT
		hideDialog() {
			this.editItemDialog = false;
		},
		updateItem() {
            this.loading = true;

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/master-distributor/update',
                data: {
                    "dist_code": this.item.dist_code,
                    "dist_name": this.item.dist_name,
                    "region_id": this.item.region_id,
                    "status" : this.item.is_active,
                },
            })
            .then((res) => {
                this.loading = false;
                if (res) {
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Berhasil Diupdate', life: 3000,});
                    this.$store.commit('setErrors', {});
                    this.$store.commit('setErrorEdit', {});
                    // this.item = {};
                    this.editItemDialog = false;
                    this.$emit('submit');
                }
            })
            .catch((err) => {
                console.log(err);
                this.loading = false;
                this.$store.commit('setErrorEdit', err.response.data.data);
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Gagal Diupdate', life: 3000,});
            });
		},
    }
}
</script>