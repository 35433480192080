<template>
    <div class="p-fluid">
        <div class="formgrid grid">
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Distributor Kode</label>
                <InputText id="dist_code" v-model="forms.dist_code" required="true" autofocus :class="{ 'p-invalid': errorAdd.dist_code }" />
                <small class="p-invalid" style="color: red" v-if="errorAdd.dist_code" >{{ errorAdd.dist_code[0] }}</small >
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Distributor Nama</label>
                <InputText id="dist_name" v-model="forms.dist_name" required="true" autofocus :class="{ 'p-invalid': errorAdd.dist_name }" />
                <small class="p-invalid" style="color: red" v-if="errorAdd.dist_name" >{{ errorAdd.dist_name[0] }}</small >
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Region</label>
                    <Dropdown id="region" dataKey="region_id" v-model="forms.region_id" :loading="loadingDropdownRegion"
                        :options="dataDropdownRegion" :class="{ 'p-invalid': errorAdd.region_id }"
                        optionLabel="region_name" optionValue="region_id" placeholder="Pilih Region"
                        :filter="true" :showClear="true" @filter="searchDropdownRegion($event, 'add')"
                    />
                    <small class="p-invalid" style="color: red" v-if="errorAdd.region_id">{{
                        errorAdd.region_id[0]
                }}</small>
            </div>
        </div>
    </div>
    <Button :loading="loading" label="Simpan" icon="pi pi-save" class="p-button-primary" @click="saveNew" />
</template>

<script>

import { mapGetters } from "vuex";

export default {
    emits: ["submit"],
    data() {
        return {
            // loading
            loading: false,
            loadingDropdownRegion: false,

            // dataDropdown
            dataDropdownRegion: null,

            // addNew
            forms: {
                dist_code: null,
                dist_name: null,
                region_id: null,
            },
        }
    },
    mounted() {
        this.$store.commit('setErrorAdd', {});
        this.searchDropdownRegion('');
    },
    computed:{
        ...mapGetters(['errorAdd']),
		...mapGetters("auth", ["user"]),
    },
    methods: {
        // DROPDOWN
        searchDropdownRegion(event, purpose, valueEdit){
            setTimeout(() => {
                if(valueEdit){
                    this.$refs.drgn.filterValue = valueEdit;
                }

                if(purpose == "add"){
                    this.loadingDropdownRegion = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/master-region-v2',
                    params: {
                        "search" : valueEdit ? valueEdit : event.value,
                    }
                })
                .then(res => {

                    if(purpose == "add"){
                        this.dataDropdownRegion = res.data.data;
                        this.loadingDropdownRegion = false;
                    }else if(purpose == null){
                        this.dataDropdownRegion = res.data.data;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            }, 250);
        },
        // ADDNEW
        saveNew() {
            this.loading = true;

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/master-distributor/create',
                data: {
                    "dist_code": this.forms.dist_code,
                    "dist_name": this.forms.dist_name,
                    "region_id": this.forms.region_id,
                },
            })
            .then((res) => {
                this.loading = false;
                if (res) {
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Berhasil Disimpan', life: 3000,});
                    this.$store.commit('setErrors', {});
                    this.$store.commit('setErrorAdd', {});
                    this.clearForms();
                    
                    this.$emit('submit');
                }
            })
            .catch((err) => {
                console.log(err);
                this.loading = false;
                this.$store.commit('setErrorAdd', err.response.data.data);
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Gagal Disimpan', life: 3000,});
            });
        },
        clearForms() {
            this.forms.dist_code = null;
            this.forms.dist_name = null;
            this.forms.region_id = null;
        },
    }
}
</script>